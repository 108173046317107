import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { API_URL } from '../constants';
import axios from 'axios';

const Contact = () => {

    const [formData, setFormData] = useState({
        fullname: '',
        email: '',
        message: ''
    });
    const [formStatus, setFormStatus] = useState({ success: false, error: false });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('test');
        try {
            await axios.post(API_URL+'/submit-contact-enquiry', formData);
            setFormStatus({ success: true, error: false });
            setFormData({ fullname: '', email: '', message: '' });
        } catch (error) {
            setFormStatus({ success: false, error: true });
        }
    };



    return (
        <section className="page-section" id="contact">
            <div className="container position-relative">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="row mb-50">
                            <div className="col-lg-12">
                                <h2 className="homepage-section-heading section-caption mb-xs-10">Contact Us</h2>
                                <h3 className="section-title mb-0">
                                    <span className="wow charsAnimIn" data-splitting="chars">Let's start the productive work.</span>
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="row mb-60 mb-sm-50">
                            <div className="col-sm-6 mb-xs-30 d-flex align-items-stretch">
                                <div className="alt-features-item border-left mt-0 wow fadeScaleIn" data-wow-delay=".3s">
                                    <div className="alt-features-icon">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
                                            <path d="M24 21h-24v-18h24v18zm-23-16.477v15.477h22v-15.477l-10.999 10-11.001-10zm21.089-.523h-20.176l10.088 9.171 10.088-9.171z"></path>
                                        </svg>
                                        <div className="alt-features-icon-s">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                                                <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3.445 17.827c-3.684 1.684-9.401-9.43-5.8-11.308l1.053-.519 1.746 3.409-1.042.513c-1.095.587 1.185 5.04 2.305 4.497l1.032-.505 1.76 3.397-1.054.516z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <h4 className="alt-features-title">Say hello</h4>
                                    <div className="alt-features-descr clearlinks">
                                        <div><a href="mailto:inquiry@legacythreads.in">inquiry@legacythreads.in</a></div>
                                        <div><Link to="tel:+91-9811546161">+91-9811546161</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row wow fadeInUp" data-wow-delay="0.5s">
                    <div className="col-md-12 mb-sm-50">
                        <form className="form contact-form pe-lg-5" onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <input
                                            type="text"
                                            name="fullname"
                                            id="fullname"
                                            className="input-lg round form-control"
                                            placeholder="Enter your name"
                                            pattern=".{3,100}"
                                            required
                                            aria-required="true"
                                            value={formData.fullname}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className="input-lg round form-control"
                                            placeholder="Enter your email"
                                            pattern=".{5,100}"
                                            required
                                            aria-required="true"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="message">Message</label>
                                <textarea
                                    name="message"
                                    id="message"
                                    className="input-lg round form-control"
                                    style={{ height: "130px" }}
                                    placeholder="Enter your message"
                                    value={formData.message}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="pt-20">
                                        <button
                                            className="submit_btn btn btn-mod btn-large btn-round btn-hover-anim"
                                            aria-controls="result"
                                            type="submit"
                                        >
                                            <span>Send Message</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="form-tip pt-20 pt-sm-0 mt-sm-20">
                                        All the fields are required. By sending the form you agree to the <Link to="#">Terms & Conditions</Link> and <Link to="#">Privacy Policy</Link>.
                                    </div>
                                </div>
                            </div>
                            <div id="result" role="region" aria-live="polite" aria-atomic="true">
                                {formStatus.success && <div className="success">Your information has been submitted successfully. We will reach out to you soon.</div>}
                                {formStatus.error && <div className="error">There is some error, please try again later.</div>}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
