import React from 'react'
import { Link } from "react-router-dom";

const GuidelinesSilhouetteKids = () => {
    return (
        <div>
            <section className="page-section bg-gray-light-1 bg-light-alpha-90 parallax-5" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'images/images/full-width-images/section-bg-1.jpg'})` }} id="home">
                <div className="container position-relative pt-30 pt-sm-50">
                    <div className="text-center">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <h1 className="hs-title-1 mb-20">
                                    <span className="wow charsAnimIn" data-splitting="chars">Guidelines To Silhouette For Kids</span>
                                </h1>
                                <div className="blog-item-data mt-30 mt-sm-10 mb-0 wow fadeInUp" data-wow-delay="0.2s">
                                    <div className="d-inline-block me-3">
                                        <Link to="#"><i className="mi-clock size-16"></i><span className="visually-hidden">Date:</span> August 17</Link>
                                    </div>
                                    <div className="d-inline-block me-3">
                                        <Link to="#"><i className="mi-user size-16"></i><span className="visually-hidden">Author:</span> Legacy Threads</Link>
                                    </div>
                                    <div className="d-inline-block me-3">
                                        <i className="mi-folder size-16"></i>
                                        <span className="visually-hidden">Categories:</span>
                                        <Link to="#">Silhouette</Link>, <Link to="#">Kids</Link>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                    </div>
                </div>
            </section>
            <section className="page-section">
                <div className="container relative">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="blog-item mb-80 mb-xs-40">
                                <img src="images/blog/post-prev-1.jpg"/>
                                <div className="blog-item-body">
                                    <p>When designing or selecting kidswear, various silhouettes can be used to ensure comfort, functionality, and style. Here are some common silhouette types in kidswear:</p>
                                    <h6>A-Line</h6>
                                    <ul>
                                    <li>Narrow at the top and gradually flares out towards the hem.</li>
                                    <li>Comfortable and allows for easy movement.</li>
                                    <li>Suitable for dresses and skirts.</li>
                                    </ul>

                                    <h6>Empire Waist</h6>
                                    <ul>
                                    <li>High waistline just below the chest with a flared skirt.</li>
                                    <li>Creates a cute, youthful look.</li>
                                    <li>Often used in dresses.</li>
                                    </ul>

                                    <h6>Fit and Flare</h6>
                                    <ul>
                                    <li>Fitted at the top and waist, with a skirt that flares out.</li>
                                    <li>Stylish and comfortable.</li>
                                    <li>Suitable for dresses and tops.</li>
                                    </ul>

                                    <h6>Tunic</h6>
                                    <ul>
                                    <li>Loose and long, usually falling to the hip or thigh.</li>
                                    <li>Can be worn over leggings or pants.</li>
                                    <li>Provides comfort and ease of movement.</li>
                                    </ul>

                                    <h6>Bubble</h6>
                                    <ul>
                                    <li>Hem is gathered or elasticized, creating a bubble effect.</li>
                                    <li>Adds a playful touch.</li>
                                    <li>Common in dresses, skirts, and rompers.</li>
                                    </ul>

                                    <h6>Shift</h6>
                                    <ul>
                                    <li>Hangs straight down from the shoulders with minimal shaping.</li>
                                    <li>Comfortable and easy to wear.</li>
                                    <li>Suitable for dresses and tops.</li>
                                    </ul>

                                    <h6>Peplum</h6>
                                    <ul>
                                    <li>Fitted bodice with a short, flared strip of fabric attached at the waist.</li>
                                    <li>Adds volume and a playful element.</li>
                                    <li>Used in tops, dresses, and skirts.</li>
                                    </ul>

                                    <h6>Overalls/Rompers</h6>
                                    <ul>
                                    <li>One-piece garments that combine a top with shorts or pants.</li>
                                    <li>Functional and easy to wear.</li>
                                    <li>Suitable for playtime and casual wear.</li>
                                    </ul>

                                    <h6>Jogger</h6>
                                    <ul>
                                    <li>Loose-fitting pants that taper at the ankle with elastic or cuffs.</li>
                                    <li>Comfortable and trendy.</li>
                                    <li>Ideal for active wear and casual outfits.</li>
                                    </ul>

                                    <h6>Skater</h6>
                                    <ul>
                                    <li>Fitted bodice with a flared skirt that typically falls above the knee.</li>
                                    <li>Allows for freedom of movement.</li>
                                    <li>Popular in dresses and skirts.</li>
                                    </ul>

                                    <h6>Boxy Fit</h6>
                                    <ul>
                                    <li>Wide and straight silhouette with little shaping.</li>
                                    <li>Provides a relaxed and modern look.</li>
                                    <li>Common in t-shirts, sweatshirts, and jackets.</li>
                                    </ul>

                                    <h6>Pinafore</h6>
                                    <ul>
                                    <li>A sleeveless dress or apron worn over a top.</li>
                                    <li>Versatile and easy to layer.</li>
                                    <li>Suitable for casual and semi-formal occasions.</li>
                                    </ul>

                                    <h6>Straight Leg</h6>
                                    <ul>
                                    <li>Pants with a uniform width from the hips to the hem.</li>
                                    <li>Provides a classic and comfortable fit.</li>
                                    <li>Common in jeans and trousers.</li>
                                    </ul>

                                    <h6>Cargo</h6>
                                    <ul>
                                    <li>Loose-fitting pants with multiple pockets.</li>
                                    <li>Functional and trendy.</li>
                                    <li>Suitable for active wear and casual outfits.</li>
                                    </ul>

                                    <cite>Understanding these silhouettes in kidswear can help in selecting or designing garments that are not only stylish but also practical and comfortable for children.</cite>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default GuidelinesSilhouetteKids