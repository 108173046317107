// AuthContext.js
import axios from 'axios';
import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from '../constants';
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
const navigate = useNavigate()
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem('authToken')
  );
 

  const login = async (data) => {
    // e.preventDefault();
  
    try {
        const res =  await axios.post(`${API_URL}/login`,data)
        const token = res.data.token; // Assuming token is in res.data.token
        // console.log(res.data.data._id,'oooooo')
      
         
        // Store the token in local storage
        localStorage.setItem('userId',res.data.data._id)
        localStorage.setItem('authToken', token);
      
        window.location.href = '/user'; 

      } catch (error) {
        console.log(error.response.data.message,'ddddddddddd')
        toast.error(error.response.data.message);
      }
   
  

    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
