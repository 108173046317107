import React from 'react'
import { Link } from "react-router-dom";

const Services = () => {
    return (
        <section className="page-section pb-0" id="services">
            <div className="container position-relative">
                <div className="row">
                    <div className="col-lg-6 mb-md-60 mb-sm-30">
                        <h2 className="homepage-section-heading section-caption mb-xs-10">Our Services</h2>
                        <h3 className="section-title mb-30"><span className="wow charsAnimIn" data-splitting="chars">From Concept to Creation.</span></h3>
                        <div className="row">
                            <div className="col-lg-10">
                                <p className="section-descr mb-50 mb-sm-30 wow fadeInUp" data-wow-delay="0.4s">
                                    Tech enabled process for better transparency and exact lead times.
                                </p>
                            </div>
                        </div>
                        <ul className="nav nav-tabs services-tabs wow fadeInUp" data-wow-delay="0.55s" role="tablist">
                            <li role="presentation">
                                <Link to="#services-item-1" className="active" aria-controls="services-item-1" role="tab" aria-selected="true" data-bs-toggle="tab">Product Development</Link>
                            </li>
                            <li role="presentation">
                                <Link to="#services-item-2" aria-controls="services-item-2" role="tab" aria-selected="false" data-bs-toggle="tab">Sampling</Link>
                            </li>
                            <li role="presentation">
                                <Link to="#services-item-3" aria-controls="services-item-3" role="tab" aria-selected="false" data-bs-toggle="tab">Inventory Building</Link>
                            </li>
                            <li role="presentation">
                                <Link to="#services-item-4" aria-controls="services-item-4" role="tab" aria-selected="false" data-bs-toggle="tab">Fabric Sourcing</Link>
                            </li>
                            <li role="presentation">
                                <Link to="#services-item-5" aria-controls="services-item-5" role="tab" aria-selected="false" data-bs-toggle="tab">Design Services</Link>
                            </li>
                            <li role="presentation">
                                <Link to="#services-item-6" aria-controls="services-item-6" role="tab" aria-selected="false" data-bs-toggle="tab">Brand Identity Development</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6 d-flex wow fadeInLeft" data-wow-delay="0.55s" data-wow-offset="275">
                        <div className="tab-content services-content">
                            <div className="tab-pane services-content-item show fade active" id="services-item-1" role="tabpanel">
                                <div className="services-text">
                                    <div className="services-text-container">
                                        <h4 className="services-title">Product Development</h4>
                                        <p className="text-gray mb-0">
                                            Transform your ideas into actionable plans. We assist with conceptualizing semi-formed concepts and provide industry insights, ensuring you make informed decisions for your product's success.
                                        </p>
                                    </div>
                                </div>
                                <img className="services-image" src="images/services/PRODUCT_DEV.png" alt="Image Description" />
                            </div>
                            <div className="tab-pane services-content-item fade" id="services-item-2" role="tabpanel">
                                <div className="services-text">
                                    <div className="services-text-container">
                                        <h4 className="services-title">Sampling</h4>
                                        <p className="text-gray mb-0">
                                            Perfect your product before launch. We create Fit Samples based on your designs, concepts, or Tech Packs, and replicate existing products through Pre-production samples to ensure everything is just right.
                                        </p>
                                    </div>
                                </div>
                                <img className="services-image" src="images/services/SAMPLE.png" alt="Image Description" />
                            </div>
                            <div className="tab-pane services-content-item fade" id="services-item-3" role="tabpanel">
                                <div className="services-text">
                                    <div className="services-text-container">
                                        <h4 className="services-title">Inventory Building</h4>
                                        <p className="text-gray mb-0">
                                            Efficiently manage your stock with our low Minimum Order Quantities (MOQs). We help you build and maintain an inventory that meets industry standards without the burden of large orders.
                                        </p>
                                    </div>
                                </div>
                                <img className="services-image" src="images/services/INVENTORY.png" alt="Image Description" />
                            </div>
                            <div className="tab-pane services-content-item fade" id="services-item-4" role="tabpanel">
                                <div className="services-text">
                                    <div className="services-text-container">
                                        <h4 className="services-title">Fabric Sourcing</h4>
                                        <p className="text-gray mb-0">
                                            Source high-quality fabrics at unbeatable prices. We connect you with the best vendors to ensure you get the right materials for your products, without compromising on quality or cost.
                                        </p>
                                    </div>
                                </div>
                                <img className="services-image" src="images/services/FABRIC.png" alt="Image Description" />
                            </div>
                            <div className="tab-pane services-content-item fade" id="services-item-5" role="tabpanel">
                                <div className="services-text">
                                    <div className="services-text-container">
                                        <h4 className="services-title">Design Services</h4>
                                        <p className="text-gray mb-0">
                                            Elevate your brand's aesthetic. From in-depth market research and brand benchmarking to curating a cohesive product range with a compelling story and inspiration, we bring your design vision to life
                                        </p>
                                    </div>
                                </div>
                                <img className="services-image" src="images/services/DESIGN_SERVICES.png" alt="Image Description" />
                            </div>
                            <div className="tab-pane services-content-item fade" id="services-item-6" role="tabpanel">
                                <div className="services-text">
                                    <div className="services-text-container">
                                        <h4 className="services-title">Brand Identity Development</h4>
                                        <p className="text-gray mb-0">
                                            Build a brand that resonates. We craft a unique brand identity and framework tailored to your target customers, ensuring your brand stands out in a crowded market.
                                        </p>
                                    </div>
                                </div>
                                <img className="services-image" src="images/services/design.png" alt="Image Description" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Services