import React from 'react'

const DesignServices = () => {
    return (
        <div>
        <section className="page-section pb-100 pb-sm-60 bg-gray-light-1 bg-light-alpha-90 parallax-5" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'images/images/full-width-images/page-title-bg-3.jpg1'})` }}>
            <div className="position-absolute top-0 bottom-0 start-0 end-0 bg-gradient-white"></div><div className="container position-relative pt-50">
                <div className="text-center">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <h1 className="hs-title-1 mb-20">
                                <span className="wow charsAnimIn" data-splitting="chars">Design Services</span>
                            </h1>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="section-descr mb-0 wow fadeIn" data-wow-delay="0.2s" data-wow-duration="1.2s">
                                        From research & conceptualization to range development, we support brand throughout the entire process to ensure it stands out in the market.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="page-section pt-0">
            <div className="container relative wow fadeInUp">
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <h4 className="mb-20">Research & Conceptualisation</h4>
                            <ol>
                                <li><b>Market Research:</b> In-depth analysis of current market trends to inform your product development.</li>
                                <li><b>Brand Benchmarking:</b> Assessing your brand’s position relative to competitors.</li>
                                <li><b>Story / Inspiration:</b> Creating a compelling narrative to guide the design process.</li>
                                <li><b>Mood Board:</b> Visual representation of the overall aesthetic and feel of the collection.</li>
                                <li><b>Color Board:</b> Selection of colors that align with the brand’s vision.</li>
                                <li><b>Trims / Fabric Board:</b> Curated selection of trims and fabrics to complement the designs.</li>
                                <li><b>Trend Analysis:</b> Identifying and incorporating current and future trends into your collection.</li>
                            </ol>
                        <hr className="mb-30" />
                        <h4 className="mb-20">Range Development</h4>
                            <ol>
                                <li><b>Designs/ Sketches:</b> Detailed and creative designs tailored to your brand.</li>
                                <li><b>Technical Sheets:</b> Comprehensive technical specifications for each design.</li>
                                <li><b>Embroidery Design:</b> Unique and intricate embroidery designs & placement exploration.</li>
                                <li><b>Print Design:</b> Custom print exploration inspired by the theme.</li>
                            </ol>
                        <hr className="mb-20" />
                        <h4 className="mb-20">Sampling Process</h4>
                            <ul>
                                <li><b>Fit Samples:</b> A minimum order of 5 fit samples is required. These samples are made in normal muslin fabric to check the fit of the style. The lead time for fit samples is 7-10 working days.</li>
                                <li><b>Pre-Production Samples:</b> Once the fit samples are approved, we proceed with pre-production samples, which are exact replicas of the mass production items. The lead time for pre-production samples is 3-4 working days, provided the fabric is available in-house.</li>
                            </ul>
                        <hr className="mb-20" />
                        <h4 className="mb-20">Minimum Order Quantity (MOQ)</h4>
                        <p>Our MOQ is set at 50 pieces per design, which can include up to 2 colors and all sizes.</p>
                        <hr className="mb-20" />
                            <p><em>We are committed to providing you with exceptional service and ensuring your satisfaction throughout the entire process. Should you have any questions or need further clarification, please do not hesitate to reach out.</em></p>
                    </div>
                </div>
            </div>
        </section>
        </div>
    )
}

export default DesignServices