import React from 'react'
import { Link } from "react-router-dom";

const GuidelinesSilhouetteWomen = () => {
    return (
        <div>
            <section className="page-section bg-gray-light-1 bg-light-alpha-90 parallax-5" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'images/images/full-width-images/section-bg-1.jpg'})` }} id="home">
                <div className="container position-relative pt-30 pt-sm-50">
                    <div className="text-center">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <h1 className="hs-title-1 mb-20">
                                    <span className="wow charsAnimIn" data-splitting="chars">Guidelines To Silhouette For Women</span>
                                </h1>
                                <div className="blog-item-data mt-30 mt-sm-10 mb-0 wow fadeInUp" data-wow-delay="0.2s">
                                    <div className="d-inline-block me-3">
                                        <Link to="#"><i className="mi-clock size-16"></i><span className="visually-hidden">Date:</span> August 17</Link>
                                    </div>
                                    <div className="d-inline-block me-3">
                                        <Link to="#"><i className="mi-user size-16"></i><span className="visually-hidden">Author:</span> Legacy Threads</Link>
                                    </div>
                                    <div className="d-inline-block me-3">
                                        <i className="mi-folder size-16"></i>
                                        <span className="visually-hidden">Categories:</span>
                                        <Link to="#">Silhouette</Link>, <Link to="#">Women</Link>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                    </div>
                </div>           
            </section>
            <section className="page-section">
                <div className="container relative">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="blog-item mb-80 mb-xs-40">
                                <div className="blog-item-body">                                       
                                    <p>When designing or selecting clothing, understanding different types of silhouettes is crucial for achieving the desired look and fit. Here are some common types of silhouettes in fashion:</p>
                                    <h6>A-Line:</h6>
                                    <img src="images/silhouette/a-line.png" width="300"/>
                                    <ul>
                                        <li>Narrow at the top and gradually flares out towards the hem, resembling the shape of the letter ""A.""</li>
                                        <li>Flattering for most body types.</li>
                                    </ul>
                                    <h6>Ball Gown:</h6>
                                    <ul>
                                        <li>Fitted bodice with a very full skirt.</li>
                                        <li>Often used for formal occasions and weddings.</li>
                                    </ul>
                                    <h6>Empire Waist:</h6>
                                    <img src="images/silhouette/empire-waist.png" width="300" />
                                    <ul>
                                        <li>High waistline just below the bust, with a skirt that flares out.</li>
                                        <li>Creates an elongated and slim look.</li>
                                    </ul>
                                    <h6>Shift:</h6>
                                    <img src="images/silhouette/shift.png" width="300" />
                                    <ul>
                                        <li>Hangs straight down from the shoulders with minimal shaping.</li>
                                        <li>Comfortable and often used for casual or semi-formal wear.</li>
                                    </ul>
                                    <h6>Mermaid/Fishtail:</h6>
                                    <img src="images/silhouette/mermaid.png" width="300" />
                                    <ul>
                                        <li>Fitted from the bust to the knee, then flares out dramatically to the hem.</li>
                                        <li>Accentuates curves and creates a dramatic effect.</li>
                                    </ul>
                                    <h6>Fit and Flare:</h6>
                                    <img src="images/silhouette/fit-and-flare.png" width="300" />
                                    <ul>
                                        <li>Fitted at the top and waist, with a skirt that flares out.</li>
                                        <li>Highlights the waist and flatters the hips and thighs.</li>
                                    </ul>
                                    <h6>Peplum:</h6>
                                    <img src="images/silhouette/peplum.png" width="300" />
                                    <ul>
                                        <li>Fitted bodice with a short, flared, gathered, or pleated strip of fabric attached at the waist.</li>
                                        <li>Adds volume around the hips.</li>
                                    </ul>
                                    <h6>Column/Tube:</h6>
                                    <img src="images/silhouette/tube.png" width="300" />
                                    <ul>
                                        <li>Straight silhouette that runs vertically from the shoulders to the hem.</li>
                                        <li>Can be form-fitting or loose.</li>
                                    </ul>
                                    <h6>Wrap:</h6>
                                    <img src="images/silhouette/wrap-around.png" width="300" />
                                    <ul>
                                        <li>Wraps around the body, typically tying at the waist.</li>
                                        <li>Adjustable and flattering for many body types.</li>
                                    </ul>
                                    <cite>Understanding these silhouettes can help in selecting the right styles for different occasions and body types.</cite>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default GuidelinesSilhouetteWomen