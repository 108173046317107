import React from 'react'
import { Link } from "react-router-dom";

const GuidelinesEthnicWomen = () => {
    return (
        <div>
            <section className="page-section bg-gray-light-1 bg-light-alpha-90 parallax-5" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'images/images/full-width-images/section-bg-1.jpg'})` }} id="home">
                <div className="container position-relative pt-30 pt-sm-50">
                    <div className="text-center">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <h1 className="hs-title-1 mb-20">
                                    <span className="wow charsAnimIn" data-splitting="chars">Guidelines To Ethnic For Women</span>
                                </h1>
                                <div className="blog-item-data mt-30 mt-sm-10 mb-0 wow fadeInUp" data-wow-delay="0.2s">
                                    <div className="d-inline-block me-3">
                                        <Link to="#"><i className="mi-clock size-16"></i><span className="visually-hidden">Date:</span> August 17</Link>
                                    </div>
                                    <div className="d-inline-block me-3">
                                        <Link to="#"><i className="mi-user size-16"></i><span className="visually-hidden">Author:</span> Legacy Threads</Link>
                                    </div>
                                    <div className="d-inline-block me-3">
                                        <i className="mi-folder size-16"></i>
                                        <span className="visually-hidden">Categories:</span>
                                        <Link to="#">Ethnic</Link>, <Link to="#">Women</Link>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                    </div>
                </div>
            </section>
            <section className="page-section">
                <div className="container relative">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="blog-item mb-80 mb-xs-40">
                                <div className="blog-item-body">
                                    <p>In the ethnic women's apparel segment, various product types reflect traditional and cultural attire from different regions. Here are broad categories in ethnic women's wear:</p>
                                    <h6>Salwar Kameez / Co-ord Set</h6>
                                    <ul>
                                    <li>Consists of a tunic (kameez) paired with loose-fitting pants (salwar) and often a dupatta (scarf).</li>
                                    <li>Available in various styles like Anarkali, straight cut, and A-line.</li>
                                    </ul>
                                    <h6>Lehenga Choli</h6>
                                    <ul>
                                    <li>A long skirt (lehenga) paired with a fitted blouse (choli) and a dupatta.</li>
                                    <li>Often heavily embroidered and worn for weddings and festive occasions.</li>
                                    </ul>
                                    <h6>Kurti</h6>
                                    <ul>
                                    <li>A shorter version of the kurta, often worn with leggings, jeans, or palazzos.</li>
                                    <li>Available in various lengths, styles, and fabrics.</li>
                                    </ul>
                                    <h6>Kaftan</h6>
                                    <ul>
                                    <li>A loose, flowing garment with wide sleeves, often worn as a dress or tunic.</li>
                                    <li>Comfortable and suitable for casual and beachwear.</li>
                                    </ul>
                                    <h6>Blouse</h6>
                                    <ul>
                                    <li>A fitted top worn with a saree, available in various styles such as backless, halter neck, and high neck.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default GuidelinesEthnicWomen