import React from 'react'
import { Link } from "react-router-dom";

const BlogPage = () => {
    return (
        <div>
            <section className="page-section bg-gray-light-1 bg-light-alpha-90 parallax-5" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'images/full-width-images/section-bg-1.jpg'})` }} id="home">
                <div className="container position-relative pt-30 pt-sm-50">
                    <div className="text-center">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <h1 className="hs-title-1 mb-20">
                                    <span className="wow charsAnimIn" data-splitting="chars">Blog</span>
                                </h1>
                                <div className="row">
                                    <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                                        <p className="section-descr mb-0 wow fadeIn" data-wow-delay="0.2s" data-wow-duration="1.2s">
                                            Discovering our brilliant insights and inspiration.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                    </div>
                </div>           
            </section>
            <section className="page-section">
                <div className="container relative">
                    <div className="row mt-n30 mb-60 mb-sm-40">
                        <div className="post-prev col-md-6 col-lg-4 mt-30">                                
                            <div className="post-prev-container">
                                <div className="post-prev-img">
                                    <Link to="/guidelines-silhouette-men"><img src="images/blog/post-prev-2.jpg" alt="Add Image Description" /></Link>
                                </div>
                                <h4 className="post-prev-title"><Link to="/guidelines-silhouette-men">Guidelines To Silhouette For Men</Link></h4>
                                <div className="post-prev-text">
                                In menswear, silhouettes are equally important for defining style, fit, and overall look. Here are some common silhouettes in the menswear category...
                                </div>
                                <div className="post-prev-info clearfix">
                                    <div className="float-start">
                                        <Link to="#"><img className="post-prev-author-img" width="30" height="30" src="images/blog/author/author-2.jpg" alt="Image Description" /></Link><Link to="#">Legacy Threads</Link>
                                    </div>
                                    <div className="float-end"><Link to="#">August 17</Link></div>
                                </div>
                            </div>                                
                        </div>
                        <div className="post-prev col-md-6 col-lg-4 mt-30">                                
                            <div className="post-prev-container">
                                <div className="post-prev-img">
                                    <Link to="/guidelines-silhouette-kids"><img src="images/blog/post-prev-1.jpg" alt="Add Image Description" /></Link>
                                </div>
                                <h4 className="post-prev-title"><Link to="/guidelines-silhouette-kids">Guidelines To Silhouette For Kids</Link></h4>
                                <div className="post-prev-text">
                                When designing or selecting kidswear, various silhouettes can be used to ensure comfort, functionality, and style. Here are some common silhouette types in kidswear...
                                </div>
                                <div className="post-prev-info clearfix">
                                    <div className="float-start">
                                        <Link to="#"><img className="post-prev-author-img" width="30" height="30" src="images/blog/author/author-1.jpg" alt="Image Description" /></Link><Link to="#">Legacy Threads</Link>
                                    </div>
                                    <div className="float-end"><Link to="#">August 17</Link></div>
                                </div>
                            </div>                                
                        </div>
                        <div className="post-prev col-md-6 col-lg-4 mt-30">                                
                            <div className="post-prev-container">
                                <div className="post-prev-img">
                                    <Link to="/guidelines-silhouette-women"><img src="images/blog/post-prev-3.jpg" alt="Add Image Description" /></Link>
                                </div>
                                <h4 className="post-prev-title"><Link to="/guidelines-silhouette-women">Guidelines To Silhouette For Women</Link></h4>
                                <div className="post-prev-text">
                                When designing or selecting clothing, understanding different types of silhouettes is crucial for achieving the desired look...
                                </div>
                                <div className="post-prev-info clearfix">
                                    <div className="float-start">
                                        <Link to="#"><img className="post-prev-author-img" width="30" height="30" src="images/blog/author/author-3.jpg" alt="Image Description" /></Link><Link to="#">Legacy Threads</Link>
                                    </div>
                                    <div className="float-end"><Link to="#">August 17</Link></div>
                                </div>
                            </div>                                
                        </div>
                        <div className="post-prev col-md-6 col-lg-4 mt-30">                                
                            <div className="post-prev-container">
                                <div className="post-prev-img">
                                    <Link to="/guidelines-ethnic-men"><img src="images/blog/post-prev-3.jpg" alt="Add Image Description" /></Link>
                                </div>
                                <h4 className="post-prev-title"><Link to="/guidelines-ethnic-men">Guidelines To Ethnic For Men</Link></h4>
                                <div className="post-prev-text">
                                "In the ethnic menswear apparel segment, various product types represent traditional and cultural attire from different regions. Here are broad categories in ethnic menswear...
                                </div>
                                <div className="post-prev-info clearfix">
                                    <div className="float-start">
                                        <Link to="#"><img className="post-prev-author-img" width="30" height="30" src="images/blog/author/author-3.jpg" alt="Image Description" /></Link><Link to="#">Legacy Threads</Link>
                                    </div>
                                    <div className="float-end"><Link to="#">August 17</Link></div>
                                </div>
                            </div>                                
                        </div>
                        <div className="post-prev col-md-6 col-lg-4 mt-30">                                
                            <div className="post-prev-container">
                                <div className="post-prev-img">
                                    <Link to="/guidelines-ethnic-kids"><img src="images/blog/post-prev-3.jpg" alt="Add Image Description" /></Link>
                                </div>
                                <h4 className="post-prev-title"><Link to="/guidelines-ethnic-kids">Guidelines To Ethnic For Kids</Link></h4>
                                <div className="post-prev-text">
                                Ethnic kidswear includes a variety of traditional and culturally inspired clothing designed for children. Here are broad categories in ethnic kidswear...
                                </div>
                                <div className="post-prev-info clearfix">
                                    <div className="float-start">
                                        <Link to="#"><img className="post-prev-author-img" width="30" height="30" src="images/blog/author/author-3.jpg" alt="Image Description" /></Link><Link to="#">Legacy Threads</Link>
                                    </div>
                                    <div className="float-end"><Link to="#">August 17</Link></div>
                                </div>
                            </div>                                
                        </div>
                        <div className="post-prev col-md-6 col-lg-4 mt-30">                                
                            <div className="post-prev-container">
                                <div className="post-prev-img">
                                    <Link to="/guidelines-ethnic-women"><img src="images/blog/post-prev-3.jpg" alt="Add Image Description" /></Link>
                                </div>
                                <h4 className="post-prev-title"><Link to="/guidelines-ethnic-women">Guidelines To Ethnic For Women</Link></h4>
                                <div className="post-prev-text">
                                In the ethnic women's apparel segment, various product types reflect traditional and cultural attire from different regions. Here are broad categories in ethnic women's wear...
                                </div>
                                <div className="post-prev-info clearfix">
                                    <div className="float-start">
                                        <Link to="#"><img className="post-prev-author-img" width="30" height="30" src="images/blog/author/author-3.jpg" alt="Image Description" /></Link><Link to="#">legacy Threads</Link>
                                    </div>
                                    <div className="float-end"><Link to="#">August 17</Link></div>
                                </div>
                            </div>                                
                        </div>
                    </div>
                    {/* <div className="pagination justify-content-center mt-30 mt-xs-10">
                        <Link to=""><i className="fa fa-chevron-left"></i></Link>
                        <Link to="" className="active">1</Link>
                        <Link to="">2</Link>
                        <Link to="">3</Link>
                        <span className="no-active">...</span>
                        <Link to="">9</Link>
                        <Link to=""><i className="fa fa-chevron-right"></i></Link>
                    </div> */}
                </div>
            </section>
        </div>
    )
}

export default BlogPage