import React, { useState } from "react";
import { Link } from "react-router-dom";

const AboutCatalog = () => {

    const [selectedCategory, setSelectedCategory] = useState("images/kidswear-catalog.png");

    const handleCategoryClick = (category) => {
        // alert('dd')
        // e.preventDefault();
        console.log(`Clicked on ${category}`);
        let imageUrl = "";
        switch (category) {
            case 'Kids Wear':
                imageUrl = "images/kidswear-catalog.png";
                break;
            case 'Mens Wear':
                imageUrl = "images/menswear-catalog.png";
                break;
            case 'Womens Wear':
                imageUrl = "images/womenswear-catalog.png";
                break;
            default:
                imageUrl = "images/kidswear-catalog.png";
        }
        setSelectedCategory(imageUrl);
    };


    return (
        <section className="page-section" id="about-us">
            <div className="container position-relative">
                <div className="row mb-xs-30">
                    <div className="col-md-12">
                        <h2 className="homepage-section-heading section-caption mb-xs-10">Our Catalog</h2>
                        {/* <h3 className="section-title mb-0"><span className="wow charsAnimIn" data-splitting="chars">Premium Quality Manufacturing</span></h3> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-5 d-flex">
                        <div className="col-md-12 col-12 wow fadeInUp"  data-wow-duration="1.2s" data-wow-offset="255">
                            {/* <h2 className="section-title mb-50 mb-sm-20">Our Catalog</h2> */}
                            <div className="call-action-2-text mb-50 mb-sm-40">
                                <dl className="accordion">
                                    <dt>
                                        <a to="#" data-value="kids">Kids Wear</a>
                                    </dt>
                                    <dd className="text-gray">
                                        <ul className="catalog-accordian">
                                            <li>Ethnic</li>
                                            <li>Western</li>
                                        </ul>
                                    </dd>
                                    <dt>
                                        <a to="#" data-value="mens">Men's Wear</a>
                                    </dt>
                                    <dd className="text-gray">
                                        <ul className="catalog-accordian">
                                            <li>Ethnic</li>
                                            <li>Western</li>
                                        </ul>
                                    </dd>
                                    <dt>
                                        <a to="#" data-value="womens">Women's Wear</a>
                                    </dt>
                                    <dd className="text-gray">
                                        <ul className="catalog-accordian">
                                            <li>Ethnic</li>
                                            <li>Western</li>
                                        </ul>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 d-flex mb-md-60 mb-xs-30">
                        <div className="call-action-1-images">
                            <div className="call-action-2-image-2 about-us-home-section-img">
                                <img id="catalogue-img" src={selectedCategory} alt="Image Description" className="wow scaleOutIn" data-wow-duration="1.2s" data-wow-offset="134" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default AboutCatalog