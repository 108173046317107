import React from 'react'
import Banner from '../Components/Banner'
import About from '../Components/About'
import Services from '../Components/Services'
import LogoType from '../Components/LogoType'
import Achievement from '../Components/Achievement'
import Testimonials from '../Components/Testimonials'
import AboutSection from '../Components/AboutSection'
import AboutCatalog from '../Components/AboutCatalog'
import Contact from '../Components/Contact'

const Home = () => {
    return (
        <main id="main">
            <Banner />
            <LogoType />
            <Achievement />
            <Services />
            <About />
            <hr className="mt-10 mb-10" />
            <Testimonials />
            <Contact />
            {/* <AboutSection /> */}
            <AboutCatalog />
        </main>
    )
}

export default Home