import React, { useEffect, useState } from 'react';
import { API_URL } from '../constants';
import axios from 'axios';

const Users = () => {
   const [order,setOrder] = useState([])
  const [users] = useState([
    { id: "AA-001", name: 'LIA/BLACK', email: '117', role: 'L', status: 'Stiching' },
    { id: "AA-002", name: 'LIA/BLACK', email: '50', role: 'XL', status: 'In Process' },
    // { id: 3, name: 'Bob Johnson', email: 'bob@example.com', role: 'User', status: 'Inactive' },
  ]);
  useEffect(() => {
    const userdata = localStorage.getItem('userId')
   
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${API_URL}/getuserorders/${userdata}`); // Replace with your actual API endpoint
        setOrder(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  return (
    
    <div className="container-fluid px-3 px-md-4 py-4 pt-100">
      <style>
        {`
          /* Internal CSS for responsiveness */
          @media (max-width: 576px) {
            .table thead {
              display: none; /* Hide the table header on small screens */
            }

            .table, 
            .table tbody, 
            .table tr, 
            .table td {
              display: block; /* Make the table elements stack */
              width: 100%; /* Full width for better spacing */
            }

            .table tr {
              margin-bottom: 1rem; /* Space between rows */
              border: 1px solid #dee2e6; /* Optional border for clarity */
            }

            .table td {
              text-align: right; /* Align text to the right */
              padding: 0.5rem; /* Add padding for touch targets */
              position: relative; /* Position for pseudo elements */
            }

            .table td::before {
              content: attr(data-label); /* Use the cell's data-label for the title */
              position: absolute; /* Position it absolutely */
              left: 0; /* Align to the left */
              padding-left: 0.5rem; /* Add some space */
              font-weight: bold; /* Bold for emphasis */
              text-align: left; /* Align text to the left */
            }
          }
            .order-label{
            color:#369694;
            }
            .page-item.active .page-link{
            background-color:#369694;
            }
            .btn-primary{
            background-color:#369694 !important;
        }
        `}
      </style>

      {/* Header Section */}
      <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between mb-4 gap-3">
       
      </div>

      {/* Main Card */}
      <div className="card shadow">
        {/* Card Header */}
        <div className="card-header py-3">
          <div className="row align-items-center g-3">
            <div className="col">
              <h6 className="m-0 font-weight-bold order-label">Order Status</h6>
            </div>
            {/* <div className="col-auto">
              <div className="input-group">
                <input 
                  type="text" 
                  className="form-control" 
                  placeholder="Search Orders..."
                />
                <button className="btn btn-primary">
                🔍
                </button>
              </div>
            </div> */}
          </div>
        </div>

        {/* Card Body */}
        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="table table-bordered m-0">
              <thead className="bg-light">
                <tr>
                  <th className="align-middle">Batch Id</th>
                  <th className="align-middle">SKU</th>
                  <th className="align-middle">Piece Count</th>
                  <th className="align-middle">Size</th>
                  <th className="align-middle">Status</th>
                </tr>
              </thead>
              <tbody>
                {order.map((od,index) => (
                  <tr key={index}>
                    <td className="align-middle" data-label="Batch Id">{od.batchId}</td>
                    <td className="align-middle" data-label="SKU">{od.sku}</td>
                    <td className="align-middle" data-label="Piece Count">{od.pieceCount}</td>
                    <td className="align-middle" data-label="Size">{od.size}</td>
                    {/* <td className="align-middle" data-label="Role">
                      <span className={`badge ${user.role === 'Admin' ? 'bg-primary' : 'bg-secondary'}`}>
                        {od.role}
                      </span>
                    </td> */}
                    <td className="align-middle" data-label="Status">
                      <span className={`badge ${od.status === 'Active' ? 'bg-success' : 'bg-danger'}`}>
                        {od.status}
                      </span>
                    </td>
                    
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          {/* <div className="px-3 py-3 border-top">
            <nav>
              <ul className="pagination justify-content-end mb-0">
                <li className="page-item disabled">
                  <a className="page-link" href="#!" tabIndex="-1">Previous</a>
                </li>
                <li className="page-item active">
                  <a className="page-link" href="#!">1</a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#!">2</a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#!">3</a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#!">Next</a>
                </li>
              </ul>
            </nav>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Users;
