import React from 'react'
import { Link } from "react-router-dom";

const Banner = () => {
    return (
        <section className="home-section bg-gray-light-1 bg-light-alpha-90 parallax-5 parallax-mousemove-scene" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'images/full-width-images/section-bg-1.jpg'})` }} id="home">
            <div className="container min-height-100vh d-flex align-items-center pt-100 pb-100 pt-sm-120 pb-sm-120">
                <div className="home-content text-start">
                    <div className="row">
                        <div className="col-md-6 d-flex align-items-center mb-sm-60">
                            <div>
                                <h1 className="hs-title-1 mb-30">
                                    <span className="wow charsAnimIn" data-splitting="chars">One Stop Solution for all inventory needs.</span>
                                </h1>
                                <ul className="hero-section-bullet-points">
                                    <li>50 Pcs Per Design MOQ</li>
                                    <li>21 Days Shipping</li>
                                    <li>Real Time Tracking Order</li>
                                </ul>
                                <div className="local-scroll mt-n10 wow fadeInUp wch-unset" data-wow-delay="0.7s" data-wow-duration="1.2s" data-wow-offset="0">
                                    <Link to="get-a-quote" className="btn btn-mod btn-large btn-round btn-hover-anim align-middle me-2 me-sm-5 mt-10"><span>Get A Free Quote Today</span></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 offset-md-1 d-flex align-items-center">
                            <div className="stack-images">
                                <div className="stack-images-1 parallax-mousemove" data-offset="30">
                                    <div className="wow clipRightIn" data-wow-delay="1.2s" data-wow-duration="1.75s">
                                        <img src="images/stack-image-1.png" alt="Image Description" />
                                    </div>
                                </div>
                                <div className="stack-images-2 parallax-mousemove" data-offset="60">
                                    <div className="wow clipRightIn" data-wow-delay="1.7s" data-wow-duration="1.75s">
                                        <img src="images/stack-image-2.png" alt="Image Description" />
                                    </div>
                                </div>
                                <div className="stack-images-3 parallax-mousemove" data-offset="90">
                                    <div className="wow clipRightIn" data-wow-delay="2.2s" data-wow-duration="1.75s">
                                        <img src="images/stack-image-3.png" alt="Image Description" width="600" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="local-scroll scroll-down-wrap-type-1 wow fadeInUp" data-wow-offset="0">
                    <div className="container">
                        <Link to="#partner-slider" className="scroll-down-1">
                            <div className="scroll-down-1-icon">
                                <i className="mi-arrow-down"></i>
                            </div>
                            <div className="scroll-down-1-text">Scroll Down</div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner