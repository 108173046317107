import React from 'react'
import { Link } from "react-router-dom";

const GuidelinesEthnicMen = () => {
    return (
        <div>
            <section className="page-section bg-gray-light-1 bg-light-alpha-90 parallax-5" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'images/images/full-width-images/section-bg-1.jpg'})` }} id="home">
                <div className="container position-relative pt-30 pt-sm-50">
                    <div className="text-center">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <h1 className="hs-title-1 mb-20">
                                    <span className="wow charsAnimIn" data-splitting="chars">Guidelines To Ethnic For Men</span>
                                </h1>
                                <div className="blog-item-data mt-30 mt-sm-10 mb-0 wow fadeInUp" data-wow-delay="0.2s">
                                    <div className="d-inline-block me-3">
                                        <Link to="#"><i className="mi-clock size-16"></i><span className="visually-hidden">Date:</span> August 17</Link>
                                    </div>
                                    <div className="d-inline-block me-3">
                                        <Link to="#"><i className="mi-user size-16"></i><span className="visually-hidden">Author:</span> Legacy Threads</Link>
                                    </div>
                                    <div className="d-inline-block me-3">
                                        <i className="mi-folder size-16"></i>
                                        <span className="visually-hidden">Categories:</span>
                                        <Link to="#">Silhouette</Link>, <Link to="#">Men</Link>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                    </div>
                </div>
            </section>
            <section className="page-section">
                <div className="container relative">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="blog-item mb-80 mb-xs-40">
                                <div className="blog-item-body">                                       
                                    <p>In the ethnic menswear apparel segment, various product types represent traditional and cultural attire from different regions. Here are broad categories in ethnic menswear:</p>
                                    <h6>Kurta</h6>
                                    <ul>
                                    <li>A long, loose-fitting shirt, often worn over pajamas or churidars.</li>
                                    <li>Can be simple or elaborately decorated with embroidery, prints, or embellishments.</li>
                                    </ul>

                                    <h6>Sherwani</h6>

                                    <ul>
                                    <li>A long coat-like garment, usually worn over a kurta and churidar or pajama.</li>
                                    <li>Often adorned with intricate embroidery, beadwork, and other embellishments.</li>
                                    <li>Commonly worn for weddings and formal occasions.</li>
                                    </ul>

                                    <h6>Achkan</h6>
                                    <ul>
                                    <li>Similar to a sherwani but typically more fitted and shorter in length.</li>
                                    <li>Worn for formal occasions and often paired with churidars or trousers.</li>
                                    </ul>

                                    <h6>Pathani Suit</h6>
                                    <ul>
                                    <li>Consists of a long kurta with a mandarin collar and straight-cut trousers.</li>
                                    <li>Inspired by traditional Afghan attire and often worn for casual and semi-formal occasions.</li>
                                    </ul>

                                    <h6>Nehru Jacket</h6>
                                    <ul>
                                    <li>A hip-length tailored coat with a mandarin collar.</li>
                                    <li>Can be worn over a kurta, shirt, or even a western-style suit.</li>
                                    </ul>

                                    <h6>Bandhgala/Jodhpuri Suit</h6>
                                    <ul>
                                    <li>A formal suit with a high-necked jacket, usually paired with trousers.</li>
                                    <li>Often worn for weddings, receptions, and other formal events.</li>
                                    </ul>

                                    <h6>Dhoti</h6>
                                    <ul>
                                    <li>A traditional garment consisting of a long piece of cloth wrapped around the waist and legs.</li>
                                    <li>Often paired with a kurta or shirt.</li>
                                    </ul>

                                    <h6>Lungi/Mundu</h6>
                                    <ul>]
                                    <li>A long piece of cloth wrapped around the waist, commonly worn in South India.</li>
                                    <li>Can be worn casually or for traditional ceremonies.</li>
                                    </ul>

                                    <h6>Churidar</h6>
                                    <ul>
                                    <li>Tight-fitting trousers that are longer than the legs and gather at the ankles.</li>
                                    <li>Often worn under kurtas, sherwanis, and achkans.</li>
                                    </ul>

                                    <h6>Pajama</h6>
                                    <ul>
                                    <li>Loose-fitting trousers, often paired with a kurta for a comfortable and traditional look.</li>
                                    <li>Commonly worn for casual and semi-formal occasions.</li>
                                    </ul>

                                    <h6>Angrakha</h6>
                                    <ul>
                                    <li>A traditional Rajasthani garment, characterized by an asymmetric opening.</li>
                                    <li>Often elaborately decorated and worn for festive occasions.</li>
                                    </ul>

                                    <h6>Pagri/Turban</h6>
                                    <ul>
                                    <li>A traditional headwear worn in various styles across different regions.</li>
                                    <li>Often signifies cultural identity and is worn during ceremonies and special occasions.</li>
                                    </ul>

                                    <h6>Waistcoat/Vest</h6>
                                    <ul>
                                    <li>A sleeveless, buttoned garment worn over a kurta or shirt.</li>
                                    <li>Adds a formal touch to ethnic outfits.</li>
                                    </ul>

                                    <h6>Jacket</h6>
                                    <ul>
                                    <li>Various styles, including short ethnic jackets, can be worn over kurtas or shirts for added style and warmth.</li>
                                    </ul>

                                    <cite>These categories encompass a wide range of traditional and contemporary ethnic menswear, each with its unique style, significance, and regional variation.</cite>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default GuidelinesEthnicMen