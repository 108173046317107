import React from 'react'
import { Link } from "react-router-dom";

const AboutUs = () => {
    return (
        <div>
            <section className="page-section pb-100 pb-sm-60 bg-gray-light-1 bg-light-alpha-90 parallax-5" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'images/images/full-width-images/page-title-bg-3.jpg1'})` }}>
                <div className="position-absolute top-0 bottom-0 start-0 end-0 bg-gradient-white"></div><div className="container position-relative pt-50">
                    <div className="text-center">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <h1 className="hs-title-1 mb-20">
                                    <span className="wow charsAnimIn" data-splitting="chars">About Us</span>
                                </h1>
                                {/* <div className="row">
                                    <div className="col-md-12">
                                        <p className="section-descr mb-0 wow fadeIn" data-wow-delay="0.2s" data-wow-duration="1.2s">
                                            From research & conceptualization to range development, we support brand throughout the entire process to ensure it stands out in the market.
                                        </p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="page-section pt-0">
                <div className="container relative wow fadeInUp">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <p><b>Legacy Threads</b> was born from a vision - A vision of excellence, quality, and a relentless passion for
                                craftsmanship. Founded by <b><Link to="https://www.linkedin.com/in/rakesh-sareen-68300255/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target='_blank'>Rakesh Sareen</Link></b> Legacy Threads began its journey as
                                the exclusive manufacturing unit for the brand <b><Link to="https://www.abhishti.com/" target="_blank">Abhishti</Link></b>.</p>
                            <p>From the start, the team worked closely with the design and concepts, ensuring every piece was crafted
                            to perfection.</p>
                            <p>However, as Abhishti grew and welcomed new investors, the need for larger inventory pushed
                            production to multiple manufacturers. This transition wasn&#39;t without its challenges.</p>
                            <p>Abhishti faced numerous hurdles in maintaining the high standards they had set, particularly in four
                            critical areas:</p>
                            <ul>
                                <li><b>MOQ</b></li>
                                <li><b>Delivery Timelines</b></li>
                                <li><b>Quality</b></li>
                                <li><b>Costs</b></li>
                            </ul>
                            <p className="mb-10">These challenges illuminated a glaring gap in the manufacturing process. The struggle to find reliable
                            partners who could deliver on all fronts was real and frustrating.</p>
                            <p>The Founder, driven by his commitment to excellence and his deep understanding of the industry,
                            decided to expand Legacy Threads.</p>
                            <p><b>Our Mission:</b> To provide a seamless, trustworthy platform not just for Abhishti, but for other brands and
                            entrepreneurs as well.</p>
                            <p>At Legacy Threads, we understand that smooth, efficient manufacturing is the backbone of the apparel
                            industry. The transparency and accountability that can be enabled by the introduction of technology at
                            every stage of product life can enable a healthy predictable environment for a business to thrive in.</p>
                            <p>It allows entrepreneurs to focus on what they do best -  building their brand and connecting with their
                            customers.</p>
                            <p>Multiple brands have joined us in this journey and are helping us build our tribe and thrive and build a
                            legacy, one thread at a time.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default AboutUs