import React from 'react'
import { Link } from "react-router-dom";

const GuidelinesSilhouetteMen = () => {
    return (
        <div>
            <section className="page-section bg-gray-light-1 bg-light-alpha-90 parallax-5" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'images/images/full-width-images/section-bg-1.jpg'})` }} id="home">
                <div className="container position-relative pt-30 pt-sm-50">
                    <div className="text-center">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <h1 className="hs-title-1 mb-20">
                                    <span className="wow charsAnimIn" data-splitting="chars">Guidelines To Silhouette For Men</span>
                                </h1>
                                <div className="blog-item-data mt-30 mt-sm-10 mb-0 wow fadeInUp" data-wow-delay="0.2s">
                                    <div className="d-inline-block me-3">
                                        <Link to="#"><i className="mi-clock size-16"></i><span className="visually-hidden">Date:</span> August 17</Link>
                                    </div>
                                    <div className="d-inline-block me-3">
                                        <Link to="#"><i className="mi-user size-16"></i><span className="visually-hidden">Author:</span> Legacy Threads</Link>
                                    </div>
                                    <div className="d-inline-block me-3">
                                        <i className="mi-folder size-16"></i>
                                        <span className="visually-hidden">Categories:</span>
                                        <Link to="#">Silhouette</Link>, <Link to="#">Men</Link>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                    </div>
                </div>           
            </section>
            <section className="page-section">
                <div className="container relative">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="blog-item mb-80 mb-xs-40">
                                <div className="blog-item-body">                                       
                                    <p>In menswear, silhouettes are equally important for defining style, fit, and overall look. Here are some common silhouettes in the menswear category:</p>
                                    
                                    <h6>Slim Fit</h6>
                                    s<img src="images/silhouette/slim-fit.png" width="300" />
                                    <ul>
                                        <li>Tailored closely to the body, providing a sleek and modern look.</li>
                                        <li>Often used in suits, shirts, and trousers.</li>
                                        <li>Highlights the body's natural shape without being too tight.</li>
                                    </ul>

                                    <h6>Regular Fit/Classic Fit</h6>
                                    <img src="images/silhouette/regular-fit.png" width="300" />
                                    <ul>
                                        <li>A classic silhouette that provides comfort without being too loose or too tight.</li>
                                        <li>Suitable for a wide range of body types.</li>
                                        <li>Offers a balance between form-fitting and ease of movement.</li>
                                    </ul>
                                    
                                    <h6>Relaxed Fit</h6>
                                    <ul>
                                        <li>Looser and more comfortable, allowing for greater movement and a casual appearance.</li>
                                        <li>Common in casual wear such as jeans, t-shirts, and sweaters.</li>
                                    </ul>
                                    
                                    <h6>Athletic Fit</h6>
                                    <ul>
                                        <li>Designed for men with a more muscular build.</li>
                                        <li>Offers extra room in the shoulders, chest, and arms while being tapered at the waist.</li>
                                        <li>Provides comfort and a tailored look for athletic body types.</li>
                                    </ul>

                                    <h6>Tapered Fit</h6>
                                    <img src="images/silhouette/trapped-fit.png" width="300" />
                                    <ul>
                                        <li>Wider at the top and gradually narrows towards the bottom.</li>
                                        <li>Common in trousers and jeans, providing a modern and stylish appearance.</li>
                                        <li>Combines elements of both slim and relaxed fits.</li>
                                    </ul>
                                    <h6>Straight Fit</h6>
                                    <img src="images/silhouette/straight-leg.png" width="300" />
                                    <ul>
                                        <li>Uniform width from the hips to the hem.</li>
                                        <li>Offers a clean and straightforward look.</li>
                                        <li>Often used in jeans and trousers for a classic style.</li>
                                    </ul>
                                    <h6>Boxy Fit</h6>
                                    <img src="images/silhouette/boxy-fit.png" width="300" />
                                    <ul>
                                        <li>Wide and straight silhouette with little to no tapering.</li>
                                        <li>Often used in jackets, coats, and some casual shirts.</li>
                                        <li>Provides a contemporary and urban look.</li>
                                    </ul>
                                    <cite>Understanding these silhouettes in menswear can help in selecting the right styles for various occasions and preferences, ensuring both comfort and a stylish appearance.</cite>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default GuidelinesSilhouetteMen