import React, { useState } from 'react';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Slider from 'react-slick';
const LogoType = () => {
    const [settings] = useState({
        infinite: true,
        slidesToShow: 7,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        arrows: false,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
    });
    return (
        <section className="page-section" id="partner-slider">
            <div className="container position-relative">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h2 className="homepage-section-heading section-title-tiny mb-30">TRUSTED BY LEADING COMPANIES</h2>
                        <Slider {...settings}>
                            <img className="client-slider-logo" src="images/clients-logos/logo-grid/1.png"  alt="Image description is here" />
                            <img className="client-slider-logo" src="images/clients-logos/logo-grid/2.png"  alt="Image description is here" />
                            <img className="client-slider-logo" src="images/clients-logos/logo-grid/3.png"  alt="Image description is here" />
                            <img className="client-slider-logo" src="images/clients-logos/logo-grid/4.png"  alt="Image description is here" />
                            <img className="client-slider-logo" src="images/clients-logos/logo-grid/5.png" alt="Image description is here" />
                            <img className="client-slider-logo" src="images/clients-logos/logo-grid/6.png"  alt="Image description is here" />
                            <img className="client-slider-logo" src="images/clients-logos/logo-grid/7.png"  alt="Image description is here" />
                            <img className="client-slider-logo" src="images/clients-logos/logo-grid/8.png"  alt="Image description is here" />
                            <img className="client-slider-logo" src="images/clients-logos/logo-grid/9.png"  alt="Image description is here" />
                            <img className="client-slider-logo" src="images/clients-logos/logo-grid/10.png" alt="Image description is here" />
                            <img className="client-slider-logo" src="images/clients-logos/logo-grid/11.png"  alt="Image description is here" />
                            <img className="client-slider-logo" src="images/clients-logos/logo-grid/12.png"  alt="Image description is here" />
                            <img className="client-slider-logo" src="images/clients-logos/logo-grid/13.png"  alt="Image description is here" />
                            <img className="client-slider-logo" src="images/clients-logos/logo-grid/14.png"  alt="Image description is here" />
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LogoType